import React from "react"
import {
  getCourseNavigation,
  useCourseStore,
} from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"

import AudioPlayer from "../../../../../components/audio-player"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import HandDrawing from "../../../../../components/hand-drawing"
import Heading from "../../../../../components/heading"
import Text from "../../../../../components/text"
import Note from "../../../../../components/note"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"

const Page = () => {
  const { setAnswer } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "reiseerinnerung" })

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext to="/kurse/reiseerinnerung/01-suedfrankreich/bewegungen/zusammenfassung" />
      }
    >
      <Seo title="Bewegungen aufzeichnen" />
      <Stack>
        <Heading as="h2" level={2}>
          Bewegungen aufzeichnen
        </Heading>
        <Paragraph>
          Hör dir den Anfang des Gedichts noch einmal an und verfolge die darin
          vorkommenden Bewegungsrichtungen. Lass dich vom Text leiten und
          beobachte, in welche Richtung er dich lenkt. Nach oben oder unten, in
          Stromlinien, Pfeilen oder Kreisen?
        </Paragraph>
        <Note variant="task">
          <Stack space={6}>
            <p>
              Starte die Wiedergabe. Achte auf Richtungsanweisungen, die das
              Gedicht dir vorgibt, und zeichne sie nach. Ein Tipp: Am besten
              beginnst du deine Zeichnung in der Mitte des Textfelds. So hast du
              Platz zu allen Seiten. Auf dem Handy oder Tablet kannst du dafür
              deinen Finger verwenden. Am Computer hältst du die linke Maustaste
              gedrückt und ziehst den Mauszeiger über das Bild.
            </p>
          </Stack>
        </Note>
        <Stack space={6}>
          <AudioPlayer
            src={`kurse/andenken`}
            title={
              <Text sans>
                Christian Reiner liest{" "}
                <Text bold as="span">
                  ›Andenken‹
                </Text>
              </Text>
            }
          />
          <HandDrawing
            ratio={0.7}
            onChange={(ref) => {
              setAnswer({
                courseId: "reiseerinnerung",
                chapterId: "01-suedfrankreich",
                taskId: "bewegungen",
                answer: ref.getSaveData(),
              })
            }}
          />
        </Stack>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
